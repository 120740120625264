import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/www/src/templates/default-mdx-page-template.tsx";
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import { Icon } from '~paragon-react';
import IconsTable from '../../components/IconsTable';
import PropsTable from '../../components/PropsTable';
import * as IconComponents from '~paragon-icons';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "icons"
    }}>{`Icons`}<a parentName="h2" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#icons"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h2>
    <p>{`Displays an svg icon from `}<inlineCode parentName="p">{`@openedx/paragon/icons`}</inlineCode>{`. See the `}<Link to="/components/icon" mdxType="Link">{`Icon Component`}</Link>{` for more information.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`// Included in this live jsx scope:
// import { Add, AddCircle } from '@openedx/paragon/icons';
<Icon src={Add} />
`}</code></pre>
    <p>{`Many of the icons below are sourced from `}<a href="https://material.io/resources/icons/">{`Material Design Sharp Icons`}</a>{`. They are designed to be sharp at 24px by 24px.`}</p>
    <br />
    <IconsTable iconNames={Object.keys(IconComponents)} mdxType="IconsTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      